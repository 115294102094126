<template>
  <Layout :show-admin="showAdmin" :show-super-admin="showSuperAdmin" :show-super-sales="showSuperSales">
    <PageHeader :title="title" :items="items" />
    <div class="row mb-4">
      <div class="col">
        <p v-if="sellLoaded" class="bold"><strong>N°{{ SellService.getNumber(sell.sellId, sell.created_date, sell.company) }}</strong></p>
        <p v-if="!sellLoaded" class="mb-1 placeholder-glow"><span class="placeholder col-3"></span></p>
      </div>
      <div class="col text-end ms-1">
        <router-link :to="'/sell/' + sell.id + '/worksheet'" class="btn btn-primary ms-1" v-if="sellLoaded">
          Voir la fiche chantier <i class="uil uil-file ms-1"></i>
        </router-link>
        <router-link :to="'/sell/' + sell.id + '/pdf'" class="btn btn-success ms-1" v-if="sellLoaded" target="_blank">
          Voir le devis <i class="uil uil-arrow-up-right ms-1"></i>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div id="sell-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-info-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Informations générales <b-spinner class="align-middle ms-3" variant="primary" v-if="!sellLoaded"></b-spinner></h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#sell-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                
                <div class="row mb-0">
                    <div class="col-md-3 col-6 mt-md-0 mt-3">
                        <p class="mb-1">Créateur :</p>
                        <h5 class="font-size-16 mt-md-3">{{ sell.user.firstname }} {{ sell.user.lastname }}</h5>
                    </div>
                </div>
                <div class="row mt-md-4 mb-0 mt-2">
                    <div class="col-md-3 col-12">
                      <p class="mb-1">Statut :</p>
                      <b-dropdown split :variant="currentColor" class="ms-auto">
                        <template v-slot:button-content>
                          {{ sell.status }}
                        </template>
                        <b-dropdown-item-button v-for="status in SellService.statusList" :key="status.label" @click="switchStatus(status)">{{ status.label }}</b-dropdown-item-button>
                      </b-dropdown>
                    </div>
                    <div class="col-md-3 col-6 mt-md-0 mt-3">
                        <p class="mb-1">Date de création :</p>
                        <h5 class="font-size-16 mt-md-3">{{ sell.created_date }}</h5>
                    </div>
                    <div class="col-md-3 col-6 mt-md-0 mt-3">
                        <p class="mb-1">Date de FDR :</p>
                        <h5 class="font-size-16 mt-md-3">{{ dateFDR() }}</h5>
                    </div>
                </div>
                <div class="row mt-md-5 mb-0 mt-3">
                    <div class="col-md-3 pe-md-5">
                        <p class="mb-1">Société* :</p>
                        <select class="form-select" v-model="chosenCompany" disabled>
                            <option :value="company.id" v-for="company in companies" :key="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-3 mt-md-0 mt-3">
                      <p class="mb-1">Source* :</p>
                      <select class="form-select" v-model="sell.source">
                        <option :value="source" v-for="source in SellService.sourcesList" :key="source.id">{{ source }}</option>
                      </select>
                    </div>
                    <div class="col-md-3 ps-md-5 mt-md-0 mt-3" v-if="sell.source == 'R1'">
                      <p class="mb-1">Nom du telepro :</p>
                      <input
                          v-model="sell.telesalesperson"
                          type="text"
                          name="telesalesperson"
                          class="form-control"
                      />
                    </div>
                    <div class="col-md-3 ps-md-5 mt-md-0 mt-3" v-if="sell.source == 'VA'">
                      <p class="mb-1">Nom du commercial :</p>
                      <input
                          v-model="sell.salesman"
                          type="text"
                          name="salesman"
                          class="form-control"
                      />
                    </div>
                </div>
                <div class="row mt-md-5 mb-0 mt-3">
                    <div class="col-md-3 pe-md-5">
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input v-model="isBinomal" type="checkbox" class="form-check-input" id="isBinomal">
                                <label class="form-check-label" for="isBinomal">Vente en binôme</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="isBinomal">
                        <p class="mb-1">Nom du binôme</p>
                        <select class="form-select" v-model="chosenAdditionnalSeller">
                            <option :value="user.id" v-for="user in filteredUsers" :key="user.id">{{ user.firstname }} {{ user.lastname }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-md-5 mb-0 mt-3">
                    <div class="col-md-3 pe-5">
                        <p class="mb-1">Date des travaux au plus tard* :</p>
                        <b-form-group
                            id="work-date"
                            class="mb-3"
                        >
                            <b-form-input
                                id="date"
                                type="date"
                                v-model="formattedWorkDate"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-3">
                      <p class="mb-1">Techniciens :</p>
                      <input
                          v-model="sell.workers"
                          type="text"
                          name="workers"
                          class="form-control"
                      />
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>
          
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-info-collapse"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Volet financier</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#sell-accordion"
              id="accordion-2"
              visible
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                
                <div class="row mb-0">
                    <div class="col-md-3">
                        <p class="mb-1">Mode de paiement* :</p>
                        <select class="form-select" v-model="sell.payment_method">
                            <option value="Comptant" :selected="sell.payment_method == 'Comptant'">Comptant</option>
                            <option value="Financement" :selected="sell.payment_method == 'Financement'">Financement</option>
                        </select>
                    </div>
                    <div class="col-md-3 ps-md-5 mt-md-0 mt-3" v-if="sell.payment_method == 'Financement'">
                        <p class="mb-1">Organisme :</p>
                        <input
                            v-model="sell.organism"
                            type="text"
                            name="organism"
                            class="form-control"
                        />
                    </div>
                    <div class="col-lg-3 col-md-5 ps-md-5 mt-md-0 mt-3">
                        <p class="mb-1">Options :</p>
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input v-model="sell.tenyear" type="checkbox" class="form-check-input" id="tenyear">
                                <label class="form-check-label" for="tenyear">Garantie décennale</label>
                            </div>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <div class="form-check">
                                <input v-model="sell.twoyear" type="checkbox" class="form-check-input" id="twoyear">
                                <label class="form-check-label" for="twoyear">Responsabilité civile 2 ans</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-md-5 mb-0 mt-3">
                    <div class="col-md-3">
                        <p class="mb-1">Suivi annuel* :</p>
                        <select class="form-select" v-model="sell.followup">
                            <option value=""></option>
                            <option value="200">200,00€</option>
                            <option value="250">250,00€</option>
                            <option value="300">300,00€</option>
                            <option value="Offert">Offert</option>
                                <option value="NON">NON</option>
                        </select>
                    </div>
                    <div class="col-md-3 ps-md-5 mt-md-0 mt-3">
                        <p class="mb-1">Frais de dossier* :</p>
                        <select class="form-select" v-model="sell.fees">
                            <option value=""></option>
                            <option value="100">100,00€</option>
                            <option value="150">150,00€</option>
                            <option value="Offert">Offert</option>
                        </select>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="sell-customer-collapse"
              v-b-toggle.accordion-3
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        03
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-0">Client</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#sell-accordion"
              id="accordion-3"
              visible
              accordion="my-accordion"
              v-if="sellLoaded"
            >
              <div class="p-4 border-top">
                
                <div class="row mb-0">
                    <div class="col-md-4">
                        <p class="mb-1">Nom :</p>
                        <h5 class="font-size-16">{{ sell.customer.firstname }} {{ sell.customer.lastname }} <span class="ms-2 badge bg-soft-primary font-size-12">{{ sell.customer.status }}</span></h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3">
                        <p class="mb-1">Adresse :</p>
                        <h5 class="font-size-16">{{ sell.customer.address }}<br>{{ sell.customer.postcode }} {{ sell.customer.city }}</h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3">
                        <p class="mb-1">E-mail :</p>
                        <h5 class="font-size-16"><a :href="'mailto:' + sell.customer.email">{{ sell.customer.email }}</a></h5>
                    </div>
                </div>
                <div class="row mt-md-4 mb-0 mt-3">
                    <div class="col-md-4">
                        <p class="mb-1">Téléphone :</p>
                        <h5 class="font-size-16"><a :href="'tel:' + sell.customer.phone">{{ sell.customer.phone }}</a></h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3">
                        <p class="mb-1">Téléphone portable :</p>
                        <h5 class="font-size-16"><a :href="'tel:' + sell.customer.mobile">{{ sell.customer.mobile }}</a></h5>
                    </div>
                    <div class="col-md-4 mt-md-0 mt-3 text-md-start text-end">
                        <router-link :to="'/customer/' + sell.customer.id" class="btn btn-primary">
                            Modifier le client
                            <i class="uil uil-pen ms-2"></i>
                        </router-link>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="sell-img-collapse"
              v-b-toggle.accordion-4
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        04
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Prestations</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-4"
              accordion="my-accordion"
              data-parent="#sell-accordion"
            >
              <div class="p-4">
                <div class="table-responsive">
                  <table class="table table-nowrap table-centered mb-0 table-slim">
                    <thead>
                      <tr>
                        <th>Prestation</th>
                        <th>Description</th>
                        <th class="text-end">Prix unitaire HT</th>
                        <th class="text-end">Quantité</th>
                        <th class="text-end" style="width: 120px">Total HT</th>
                        <th class="text-end" style="width: 50px"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in sell.sellItems" :key="item.id">
                        <td>
                          <strong>{{ item.title }}</strong>
                        </td>
                        <td class="description">{{ item.description }}</td>
                        <td class="text-end">{{ item.price | price }}</td>
                        <td class="text-end">{{ item.quantity }}</td>
                        <td class="text-end">{{ item.total | price }}</td>
                        <td class="text-end">
                          <a href="javascript:void(0);" class="px-2 text-danger" content="Supprimer" v-tippy @click="onDeleteSellItem(item.id)">
                            <i class="uil uil-trash-alt font-size-18"></i>
                          </a>
                        </td>
                      </tr>
                    <tr>
                      <td scope="row" colspan="4" class="text-end">
                        <strong>Total HT</strong>
                      </td>
                      <td class="text-end">{{ sell.total | price }}</td>
                    </tr>
                    <tr>
                      <td scope="row" colspan="4" class="text-end">
                        <strong>TVA</strong>
                      </td>
                      <td class="text-end">{{ sell.totalTaxes | price }}</td>
                    </tr>
                    <tr>
                      <td scope="row" colspan="4" class="text-end">
                        <strong>Total TTC</strong>
                      </td>
                      <td class="text-end">{{ sell.totalWithTaxes | price }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-print-none mt-4">
                  <div class="text-end">
                    <button v-b-modal.modal-add-item class="btn btn-primary w-md ms-1">Ajouter une prestation</button>
                    <AddSellItem @items-updated="onSellUpdated" :sell="sell" />
                  </div>
                </div>
                <div class="table-responsive mt-5" v-if="sell.payment_method == 'Financement'">
                  <table class="table table-bordered mb-0 boxed financial-section">
                    <thead>
                      <tr>
                        <th>Prix comptant</th>
                        <th>Versement initial</th>
                        <th>Montant du crédit</th>
                        <th>Mensualité sans assu.</th>
                        <th>Mensualité avec assu.</th>
                        <th>Coût total de l'achat à crédit</th>
                        <th>Durée</th>
                        <th>Taux débiteur fixe</th>
                        <th>TAEG</th>
                        <th>Nombre de mensualité</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><currency-input v-model="financialSection.price"></currency-input></td>
                        <td><currency-input v-model="financialSection.deposit"></currency-input></td>
                        <td><currency-input v-model="financialSection.credit"></currency-input></td>
                        <td><currency-input v-model="financialSection.monthly"></currency-input></td>
                        <td><currency-input v-model="financialSection.monthlyWithIns"></currency-input></td>
                        <td><currency-input v-model="financialSection.total"></currency-input></td>
                        <td><input class="form-control" type="text" v-model="financialSection.duration"></td>
                        <td><currency-input v-model="financialSection.rate"></currency-input></td>
                        <td><currency-input v-model="financialSection.taeg"></currency-input></td>
                        <td><input class="form-control" type="number" v-model="financialSection.months"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-5
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        05
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Détails/Remarques</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-5"
              accordion="my-accordion"
              data-parent="#sell-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-12">
                      <textarea
                        class="form-control"
                        v-model="sell.details"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-6
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        06
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Commentaire interne</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-6"
              accordion="my-accordion"
              data-parent="#sell-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-12">
                      <textarea
                        class="form-control"
                        v-model="sell.comment"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-7
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        07
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Photos</h5>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-7"
              accordion="my-accordion"
              data-parent="#sell-accordion"
            >
              <div class="p-4 border-top">
                <div class="row mb-5" v-if="sell.images && sell.images.length > 0">
                  <div class="popup-gallery">
                    <div
                      v-for="(img, index) in imgs"
                      :key="index"
                      class="float-start me-3 thumbnail"
                    >
                      <img @click="() => showImg(index)" :src="img.src" width="120" />
                      <button
                        type="button"
                        class="btn btn-sm btn-soft-danger rounded-pill"
                        @click.prevent="onDeleteSellImage(img.id)"
                        content="Supprimer" v-tippy
                      >
                        <i class="uil uil-trash-alt text-danger"></i>
                      </button>
                    </div>
                    <vue-easy-lightbox
                      :visible="visible"
                      :imgs="imgs"
                      :index="index"
                      @hide="handleHide"
                    ></vue-easy-lightbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <vue-dropzone
                      id="dropzone"
                      ref="myVueDropzone"
                      :use-custom-slot="true"
                      :options="dropzoneOptions"
                      v-on:vdropzone-sending="sendingEvent"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>Glisser-déposer des fichiers ou cliquer pour parcourir.</h4>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <button @click="updateSell()" class="btn btn-success ms-1">
          <i class="fa fa-save me-2"></i> Sauvegarder
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
  import Swal from "sweetalert2";
  import api from '@/api/api';

  import vue2Dropzone from "vue2-dropzone";

  import Layout from "../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import moment from 'moment';
  import AddSellItem from "@/forms/add-sellitem";
  import SellService from "@/services/sell.service";
  import Utilities from "@/services/utilities";

  import "vue2-dropzone/dist/vue2Dropzone.min.css";
  import TokenService from '@/services/token.service';

  export default {
    page: {
      title: "Détail d'une vente",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: {
      vueDropzone: vue2Dropzone,
      Layout,
      PageHeader,
      AddSellItem
    },
    data() {
      return {
        SellService: SellService,
        title: "Détail d'une vente",
        items: [
          {
              text: "Accueil",
              to: "/"
          },
          {
              text: "Ventes",
              to: "/sales"
          },
          {
            text: "Détail d'une vente",
            active: true,
          },
        ],
        dropzoneOptions: {
          url: process.env.VUE_APP_API_URL + '/sell_images',
          maxFiles: 5,
          thumbnailWidth: 150,
          acceptedFiles: 'image/*,.jpg,.jpeg,.png',
          dictFileTooBig: "Fichier trop lourd ({{filesize}}MiB). Poids max : {{maxFilesize}}MiB.",
          dictInvalidFileType: "Seuls les fichiers de type image sont acceptés",
          dictMaxFilesExceeded: "Pas plus de 5 photos par vente ne sont acceptés",
          maxFilesize: 0.5,
          headers: {
            'Cache-Control': null,
            'X-Requested-With': null,
            'Authorization': 'Bearer ' + TokenService.getLocalAccessToken()
          }
        },
        sellLoaded: false,
        sell: {},
        companies: [],
        chosenCompany: null,
        currentColor: 'light',
        formattedWorkDate: '',
        financialSection: {},
        imgBaseUrl: process.env.VUE_APP_IMG_URL,
        visible: false,
        index: 0,
        chosenAdditionnalSeller: null,
        isBinomal: false,
        users: []
      };
    },
    props: ["showAdmin", "showSuperAdmin", "showSuperSales"],
    mounted() {
      if (!this.$props.showAdmin && !this.$props.showSuperSales) {
        this.$router.push('/');
      }
      if (this.$route.params.id) {
        this.loadSell(this.$route.params.id);
        this.loadCompanies();
        this.loadUsers();
        this.financialSection = {...SellService.financialSection};
      }
    },
    computed: {
      totalWithTaxes() {
        return (this.sell.totalWithTaxes) ? this.sell.totalWithTaxes : '';
      },
      imgs() {
        var imgs = [];
        for (var i = 0; i < this.sell.images.length; i++) {
          let img = {
            src: this.imgBaseUrl + this.sell.images[i].name,
            id: this.sell.images[i].id
          };
          imgs.push(img);
        }
        return imgs;
      },
      filteredUsers() {
        if (!this.sell.user || this.users.length === 0) {
          return [];
        }
        return this.users.filter(user => user.id != this.sell.user.id);
      }
    },
    watch: {
      totalWithTaxes(val) {
        this.financialSection.price = val;
      }
    },
    methods: {
      showImg(index) {
        this.index = index;
        this.visible = true;
      },
      handleHide() {
        this.visible = false;
      },
      sendingEvent (file, xhr, formData) {
        formData.append('sell', '/api/sales/' + this.sell.id);
      },
      formatWorkDate() {
        if (this.sell.workDate) {
          let date = moment(this.sell.workDate, "DD/MM/YYYY H:i");
          this.formattedWorkDate = date.format('YYYY-MM-DD');
        }
      },
      switchStatus(status) {
        this.sell.status = status.label;
        this.currentColor = status.color;
      },
      loadSell(id) {
        api
          .get('/sales/' + id)
          .then(
              (response) => {
                  if (response && response.status == 200) {
                      this.sell = response.data;
                      this.currentColor = SellService.getStatusColor(this.sell.status);
                      this.chosenCompany = this.sell.company.id;
                      if (this.sell.additionnalSeller && !isNaN(this.sell.additionnalSeller.id)) {
                        this.isBinomal = true;
                        this.chosenAdditionnalSeller = this.sell.additionnalSeller.id;
                      }
                      this.sellLoaded = true;
                      this.formatWorkDate();
                      if (this.sell.financialSection && Object.keys(this.sell.financialSection).length > 0) {
                        this.financialSection = {...this.sell.financialSection};
                      }
                  }
              },
              () => {
                  Utilities.errormsg();
                  this.sellLoaded = true;
              }
          )
      },
      loadCompanies() {
        api
          .get('/companies')
          .then(
              (response) => {
                  if (response && response.status == 200) {
                      this.companies = response.data;
                  }
              },
              () => {
                  Utilities.errormsg();
              }
          )
      },
      loadUsers() {
          api
              .get('/additionnal-users')
              .then(
                  (response) => {
                      if (response && response.status == 200) {
                          this.users = Object.values(response.data);
                      }
                  },
                  () => {
                      Utilities.errormsg();
                  }
              )
      },
      dateFDR() {
        if (this.sell.created_date) {
          let date = moment(this.sell.created_date, "DD/MM/YYYY");
          date.add(14, 'days');
          return date.format('DD/MM/YYYY');  
        } else {
          return '';
        }
      },
      sellValid() {
        this.errors = [];

        if (this.sell.source == '') {
          this.errors.push("Veuillez sélectionner une source");
        }
        
        if (this.sell.workDate == '') {
          this.errors.push("Veuillez sélectionner une date");
        }

        if (this.sell.payment_method == '') {
          this.errors.push("Veuillez sélectionner un mode de paiement");
        }
        
        if (this.sell.followup == '') {
          this.errors.push('Veuillez sélectionner un montant pour le suivi annuel');
        }
        if (this.sell.fees == '') {
          this.errors.push("Veuillez sélectionner un montant pour les frais de dossier");
        }

        if (this.errors.length == 0) {
            return true;
        } else {
            Swal.fire("Erreur", this.errors[0], "error");
            return false;
        }
      },
      updateSell: function () {
          if (this.sellValid()) {
              let sellToSend = {...this.sell};
              delete sellToSend.company;
              delete sellToSend.id;
              delete sellToSend.customer;
              sellToSend.paymentMethod = this.sell.payment_method;
              delete sellToSend.payment_method;
              delete sellToSend.sellItems;
              sellToSend.workDate = this.formattedWorkDate;
              sellToSend.financialSection = {...this.financialSection};

              if (this.isBinomal && this.chosenAdditionnalSeller && !isNaN(this.chosenAdditionnalSeller)) {
                sellToSend.additionnalSeller = '/api/users/' + this.chosenAdditionnalSeller;
              } else {
                sellToSend.additionnalSeller = null;
              }

              if (sellToSend.paymentMethod === 'Comptant') {
                sellToSend.organism = '';
              }

              if (sellToSend.source === 'VA') {
                sellToSend.telesalesperson = '';
              } else if (sellToSend.source === 'R1') {
                sellToSend.salesman = '';
              }

              api
                  .patch('/sales/' + this.sell.id, sellToSend, {
                      headers: {
                          'Content-Type': 'application/merge-patch+json'
                      }
                  })
                  .then(
                      (response) => {
                          if (response && response.status == 200) {
                              Utilities.successmsg("La vente a bien été modifiée !");
                          }
                      },
                      () => {
                          Utilities.errormsg();
                      }
                  )
          }
      },
      closeModal() {
          this.$bvModal.hide('modal-add-item');
      },
      onSellUpdated() {
          this.closeModal();
          this.loadSell(this.sell.id);
      },
      onDeleteSellItem(id) {
        Swal.fire({
          title: "Attention",
          text: "Êtes-vous sûr de vouloir supprimer cette prestation ? Cette action est irréversible.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Supprimer",
          cancelButtonText: "Annuler",
        }).then((result) => {
          if (result.value) {
            api
              .delete('/sell_items/' + id)
              .then(
                  (response) => {
                      if (response && response.status == 204) {
                          Swal.fire({
                            title: "Succès",
                            text: "La prestation a bien été supprimée !",
                            timer: 1500,
                            icon: "success",
                            confirmButtonColor: "#2e58a6",
                            confirmButtonText: "Fermer",
                          });
                          this.onSellUpdated();
                      }
                  },
                  () => {
                      Utilities.errormsg();
                  }
              )
          }
        });
      },
      onDeleteSellImage(id) {
        Swal.fire({
          title: "Attention",
          text: "Êtes-vous sûr de vouloir supprimer cette image ? Cette action est irréversible.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Supprimer",
          cancelButtonText: "Annuler",
        }).then((result) => {
          if (result.value) {
            api
              .delete('/sell_images/' + id)
              .then(
                  (response) => {
                      if (response && response.status == 204) {
                          Swal.fire({
                            title: "Succès",
                            text: "L'image a bien été supprimée !",
                            timer: 1500,
                            icon: "success",
                            confirmButtonColor: "#2e58a6",
                            confirmButtonText: "Fermer",
                          });
                          this.onSellUpdated();
                      }
                  },
                  () => {
                      Utilities.errormsg();
                  }
              )
          }
        });
      }
    },
    middleware: "authentication",
  };
</script>

<style scoped lang="scss">
  .thumbnail {
    margin: 4px;
    border: 2px solid #f5f6f8;
    border-radius: 4px;
  }

  .table-responsive {
    input, ::v-deep input {
      border: none;
      &:focus {
        outline: none !important;
      }
    }

    table.boxed {
      tr {
        vertical-align: top;
      }
      td {
        max-width: 100px;
      }
      input {
        max-width: 80px;
      }
    }
  }

  .thumbnail {
    position: relative;
    cursor: pointer;
    border: none;

      img {
        border-radius: 5px;

        &:hover {
          & + .btn {
            opacity: 1;
          }
        }
      }
      .btn {
        opacity: 0;
        transition: opacity 0.3s linear;
        position: absolute;
        right: -10px;
        top: -10px;

        &:hover {
          opacity: 1;
        }
      }
  }

  td.description {
    white-space: pre-wrap;
  }

  .financial-section {
    td {
      padding-right: 0;
      padding-left: 0;
    }
  }
</style>